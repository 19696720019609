<template>
    <ion-page>
        <ion-content :scroll-events="false">
            <div class="column">
                <div
                    class="grow text-xs-center"
                    style="padding-left: 48px; padding-right: 48px"
                >
                    <img
                        src="../assets/images/payment-success.png"
                        alt=""
                        style="width: 100%; height: auto; padding-top: 48px"
                    />
                    <div class="pt-3 pb-2">
                        <i
                            class="mdi mdi-check-circle success--text"
                            style="font-size: 38px"
                        ></i>
                    </div>
                    <div class="title font-16 pb-4">Đã bán vé thành công!</div>
                    <div class="row justify-space-between pb-2">
                        <div>Tổng tiền:</div>
                        <div>{{ `${$MoneyFormat(model.TongTien)}đ` }}</div>
                    </div>
                    <div class="row justify-space-between pb-2">
                        <div>Số điện thoại:</div>
                        <div>{{ formatSdt }}</div>
                    </div>
                    <div class="row justify-space-between pb-2">
                        <div class="text-xs-left">Hình thức thanh toán:</div>
                        <div class="text-xs-right">
                            {{ model.HinhThucThanhToan }}
                        </div>
                    </div>
                </div>
                <div
                    class="container-md fluid"
                    style="border-top: 1px solid #dadce0"
                >
                    <div class="row justify-space-between align-center">
                        <ion-button
                            color="primary"
                            fill="clear"
                            @click="$router.push('/')"
                        >
                            Trang chủ
                        </ion-button>
                        <ion-button
                            color="primary"
                            expand="block"
                            @click="
                                $router.push({
                                    path: '/tabs/tab3',
                                    query: thongTinChuyenDi,
                                })
                            "
                        >
                            Tiếp tục
                        </ion-button>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import DonHang from "../../class/DonHang";
import { IonContent, IonPage, IonButton } from "@ionic/vue";
export default {
    layout: "none",
    components: {
        IonContent,
        IonPage,
        IonButton,
    },
    data() {
        return {
            thongTinChuyenDi: {
                guidChuyenDi: "",
                guidXe: "",
                maChuyenDi: "",
                guidDoanhNghiep: "",
            },
            model: {
                TongTien: 0,
                HinhThucThanhToan: "",
                SoDienThoai: "",
            },
            ParamThongBao: {
                state: "Error",
                title: "Thông báo!",
                message: "Vui lòng kiểm tra lại",
            },
        };
    },
    computed: {
        formatSdt() {
            return this.model.SoDienThoai.replace(
                /(\d{4})(\d{3})(\d{3})/,
                "$1 $2 $3"
            );
        },
    },
    methods: {
        thongBaoLoi(type, message, log) {
            let modelToast = {
                isVisible: true,
                message: message,
                type: type,
            };
            this.emitter.emit("onToast", modelToast);
        },
        // async layThongTinVe(maGiaoDich) {
        //     let layFilePDF = true;
        //     try {
        //         let ttVe = await new DonHang().layThongTinVe(
        //             maGiaoDich,
        //             layFilePDF
        //         );
        //         // var banTheHienVes = ttVe.GetData();
        //         if (ttVe.Data.status) {
        //             let banTheHienVes = ttVe.Data.data;
        //             if (layFilePDF) {
        //                 this.layVeInTheoPDF(banTheHienVes);
        //             } else {
        //                 this.layVeInChoMayBanVe(banTheHienVes);
        //             }
        //         } else {
        //             return this.thongBaoLoi(
        //                 "error",
        //                 "In thất bại! " + ttVe.Data.message
        //             );
        //         }
        //     } catch (error) {
        //         this.error = true;
        //         console.error(error);
        //     }
        // },
        async layThongTinVe(maGiaoDich) {
            try {
                let rs = await this.$AppClass.InVe(
                    this.$i18n.t("url.InVe"),
                    "MaGiaoDich",
                    maGiaoDich
                );
                if (rs == "error") {
                    return this.thongBaoLoi("error", "In thất bại! ");
                }
                this.$stopLoading;
            } catch (error) {
                this.$stopLoading;
                this.error = true;
                console.error(error);
            }
        },
        layVeInTheoPDF(data) {
            for (var datum of data)
                fetch("data:application/pdf;base64," + datum)
                    .then(function (resp) {
                        return resp.blob();
                    })
                    .then(function (blob) {
                        var objectURL = URL.createObjectURL(blob);
                        var iframe = document.createElement("iframe");
                        document.body.appendChild(iframe);

                        iframe.style.display = "none";
                        iframe.onload = function () {
                            setTimeout(function () {
                                setTimeout(() => {
                                    iframe.focus();
                                    iframe.contentWindow.print();
                                }, 10);
                            }, 0);
                        };
                        iframe.src = objectURL;
                    });
        },
        layVeInChoMayBanVe(data) {
            let self = this;
            var printPromises = [];
            for (var datumIndex in data) {
                var datum = data[datumIndex];
                var rs = fetch("data:application/pdf;base64," + datum)
                    .then(function (resp) {
                        return resp.arrayBuffer();
                    })
                    .then(function (bytes) {
                        return self.print(
                            Array.from(new Int8Array(bytes)),
                            datumIndex
                        );
                    });
                printPromises.push(rs);
            }
            Promise.all(printPromises).then(function (err) {
                console.error("Sunmi print error", err);
                alert(err + "");
            });
        },
        async print(bytes, veIndex) {
            console.log(
                "🚀 ~ file: thanh-toan-thanh-cong.vue ~ line 186 ~ print ~ bytes",
                bytes
            );
            var ua = navigator.userAgent.toLowerCase();
            var isAndroid = ua.indexOf("android") > -1;
            var isIphone = ua.indexOf("iphone") > -1;
            console.log(
                "🚀 ~ file: thanh-toan-thanh-cong.vue ~ line 196 ~ print ~ window",
                window
            );
            if (isAndroid) {
                // if (window.PrinterSunmi) {
                //     window.PrinterSunmi.print(bytes);
                //     return true;
                // }
                window.print(bytes);
            } else if (isIphone) {
                window.print(bytes);
            } else {
                throw new Error("loi ve thu " + (veIndex + 1));
            }
        },
    },
    mounted() {
        let data = this.$route.query;
        if (data) {
            this.model.TongTien = parseInt(data.tongTien);
            this.model.HinhThucThanhToan = data.tenVi;
            this.model.SoDienThoai = data.soDienThoai;
        }
        if (data.CheckInVe == "true") {
            this.layThongTinVe(data.MaGiaoDich);
        }
        //Lấy thông tin chuyến đi để về trang chủ
        let thongTinChuyenDi = JSON.parse(
            localStorage.getItem("ThongTinChuyenDi")
        );
        if (thongTinChuyenDi) {
            this.thongTinChuyenDi = thongTinChuyenDi;
        }
    },
};
</script>
<style lang="scss" scoped>
.header-style {
    width: 150%;
    position: absolute;
    left: -26%;
    height: 100%;
    background: linear-gradient(
        to bottom right,
        $primary,
        rgb(3 169 244 / 34%)
    );
    bottom: 1px;
    border-bottom-left-radius: 61%;
    border-bottom-right-radius: 61%;
}
.divider {
    border-bottom: 1px solid $border;
}
</style>

