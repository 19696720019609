class DonHang {
    constructor(
        idDonHang,
        idNguoiTao,
        maDinhDanh,
        soDienThoai,
        hoTen,
        email,
        chuyenDiId,
        ghiChu,
        trangThaiDonHang,
        chietKhau,
        noiBan,
        soTienDaThanhToan,
        tongTienPhaiThanhToan
    ) {
        this.idDonHang = idDonHang;
        this.idNguoiTao = idNguoiTao;
        this.maDinhDanh = maDinhDanh;
        this.soDienThoai = soDienThoai;
        this.hoTen = hoTen;
        this.email = email;
        this.chuyenDiId = chuyenDiId;
        this.ghiChu = ghiChu;
        this.trangThaiDonHang = trangThaiDonHang;
        this.chietKhau = chietKhau;
        this.noiBan = noiBan;
        this.soTienDaThanhToan = soTienDaThanhToan;
        this.tongTien = 0;
        this.tongTienChuaThayDoi = 0;
        this.soLuong = 0;
        this.donGia = 0;
        this.tongTienPhaiThanhToan = tongTienPhaiThanhToan;
    }
    async thanhToanQR(
        idVi,
        maChuyenDi,
        danhSachMaSoGhe = [],
        guidDoanhNghiep,
        maDiemXuong,
        tenDiemXuong,
        soDienThoai,
        giaVe,
        phatHanhVe,
        type,
        ToaDo
    ) {
        console.log(
            "%c  ",
            "color: white; background-color: #95B46A",
            "=========>"
        );
        if (!idVi) {
            return new Error("Không có idVi");
        }
        if (!maChuyenDi) {
            return new Error("Không có maChuyenDi");
        }
        if (!guidDoanhNghiep) {
            return new Error("Không có guidDoanhNghiep");
        }
        window.$loading.show();
        let query = {
            idVi: idVi,
            idPhien: null,
            maChuyenDi: maChuyenDi,
            danhSachMaSoGhe: danhSachMaSoGhe || [],
            soDienThoai: soDienThoai,
            hoTen: this.hoTen,
            email: this.email,
            ghiChu: this.ghiChu,
            guidDoanhNghiep: guidDoanhNghiep,
            maDiemXuong: maDiemXuong,
            tenDiemXuong: tenDiemXuong,
            giaVe: giaVe,
            phatHanhVe: phatHanhVe,
            ToaDo: ToaDo,
        };
        let rs;
        if (type == window.$i18n.global.t("StringCheck.CoSoDoGhe")) {
            rs = await window.$Core.Api.DonHang(
                window.$i18n.global.t("url.ThanhToan")
            ).Post(query);
        } else if (
            type == window.$i18n.global.t("StringCheck.KhongCoSoDoGhe")
        ) {
            rs = await window.$Core.Api.DonHang(
                window.$i18n.global.t("url.GiaoDichQRKhongSoDoCho")
            ).Post(query);
        } else if (type == window.$i18n.global.t("StringCheck.GhePhu")) {
            rs = await window.$Core.Api.DonHang(
                window.$i18n.global.t("url.GiaoDichQRMuaVeGhePhu")
            ).Post(query);
        }

        window.$loading.hide();
        return rs;
    }
    async thanhToanTienMat(
        maChuyenDi,
        guidXe,
        tongTienThanhToan,
        tongTienSauGiamTru,
        danhSachMaSoGhe = [],
        guidDoanhNghiep,
        DiemXuong,
        phatHanhVe,
        ToaDo
    ) {
        if (!maChuyenDi) {
            return new Error("Không có maChuyenDi");
        }
        if (!guidXe) {
            return new Error("Không có guidXe");
        }
        if (!guidDoanhNghiep) {
            return new Error("Không có guidDoanhNghiep");
        }
        window.$loading.show();
        let rs = await window.$Core.Api.DonHang(
            window.$i18n.global.t("url.BanVeTaiAppLaiXe")
        ).Post({
            maChuyenDi: maChuyenDi,
            guidXe: guidXe,
            guidDoanhNghiep: guidDoanhNghiep,
            danhSachMaSoGhe: danhSachMaSoGhe,
            soDienThoai: this.soDienThoai.trim(),
            hoTen: this.hoTen,
            email: this.email,
            ghiChu: this.ghiChu || "",
            tongTienThanhToan: tongTienThanhToan,
            tongTienSauGiamTru: tongTienSauGiamTru,
            toaDo: ToaDo,
            phatHanhVe: phatHanhVe,
            maDiemXuong: DiemXuong.guidDiemXuong || null,
            tenDiemXuong: DiemXuong.tenDiemXuong || null,
        });
        window.$loading.hide();
        return rs;
    }
    async banVeGhePhu(
        guidChuyenDi,
        giaVe,
        giaVeSauGiamTru,
        soDienThoai,
        soLuong,
        phatHanhVe,
        maDiemXuong,
        tenDiemXuong,
        ToaDo
    ) {
        if (!guidChuyenDi) {
            return new Error("Không có maChuyenDi");
        }
        window.$loading.show();
        let rs = await window.$Core.Api.DonHang(
            window.$i18n.global.t("url.BanVeGhePhu")
        ).Post({
            guidChuyenDi: guidChuyenDi,
            giaVe: giaVe,
            giaVeSauGiamTru: giaVeSauGiamTru,
            soDienThoai: soDienThoai,
            soLuong: soLuong,
            phatHanhVe: phatHanhVe,
            maDiemXuong: maDiemXuong,
            tenDiemXuong: tenDiemXuong,
            ToaDo: ToaDo,
        });
        window.$loading.hide();
        return rs;
    }
    async banVeKhongCoSoDoGhe(
        guidChuyenDi,
        giaVe,
        giaVeSauGiamTru,
        soDienThoai,
        soLuong,
        phatHanhVe,
        maDiemXuong,
        tenDiemXuong,
        ToaDo
    ) {
        if (!guidChuyenDi) {
            return new Error("Không có maChuyenDi");
        }
        window.$loading.show();
        let rs = await window.$Core.Api.DonHang(
            window.$i18n.global.t("url.BanVeKhongCoSoDoGhe")
        ).Post({
            guidChuyenDi: guidChuyenDi,
            giaVe: giaVe,
            giaVeSauGiamTru: giaVeSauGiamTru,
            soDienThoai: soDienThoai,
            soLuong: soLuong,
            phatHanhVe: phatHanhVe,
            maDiemXuong: maDiemXuong,
            tenDiemXuong: tenDiemXuong,
            ToaDo: ToaDo,
        });
        window.$loading.hide();
        return rs;
    }
    async kiemTraThanhToan(maGiaoDich) {
        if (!maGiaoDich) {
            return new Error("Không có maGiaoDich");
        }
        let rs = await window.$Core.Api.DonHang(
            window.$i18n.global.t("url.KiemTraThanhToan"),
            {
                maGiaoDich: maGiaoDich,
            }
        ).Get();
        return rs;
    }
    async huyThanhToan(maGiaoDich) {
        if (!maGiaoDich) {
            return "Không có maGiaoDich";
        }
        window.$loading.show();
        let rs = await window.$Core.Api.DonHang(
            window.$i18n.global.t("url.HuyThanhToan"),
            {
                maGiaoDich: maGiaoDich,
            }
        ).Get();
        window.$loading.hide();
        return rs;
    }
    async hetHanThanhToan(maGiaoDich) {
        window.$loading.show();
        let rs = await window.$Core.Api.DonHang(
            window.$i18n.global.t("url.HetHanThanhToan")
        ).Post({
            maGiaoDich: maGiaoDich,
        });
        window.$loading.hide();
        if (rs.StatusCode === 200) {
            return rs.Data.data;
        }
        return false;
    }
    async ketQuaGiaoDich(maGiaoDich) {
        window.$loading.show();
        let rs = await window.$Core.Api.DonHang(
            window.$i18n.global.t("url.KetQuaGiaoDich"),
            {
                maGiaoDich: maGiaoDich,
            }
        ).Get();
        window.$loading.hide();
        if (rs.StatusCode === 200) {
            return rs.Data.data;
        }
        return null;
    }
    tongSoTien() {
        let tongTien = 0;
        if (this.donGia <= 0 || this.soLuong < 0) {
            return 0;
        }
        tongTien = this.donGia * this.soLuong;
        return tongTien;
    }
    tienChietKhau() {
        let tienChietKhau = 0;
        if (
            this.tongTienChuaThayDoi <= 0 ||
            this.tongTienChuaThayDoi <= this.tongSoTien()
        ) {
            return 0;
        }
        tienChietKhau = this.tongTienChuaThayDoi - this.tongSoTien();
        return tienChietKhau;
    }
    thucThu() {
        // let thucThu = 0;
        // thucThu = this.tongTienPhaiThanhToan - this.tienChietKhau();
        if (this.tongTienPhaiThanhToan < this.tongSoTien()) {
            return this.tongSoTien();
        }
        return this.tongSoTien();
        // return thucThu >= 0 ? thucThu : 0;
    }
    async layThongTinVe(maGiaoDich, layFilePdf = true) {
        window.$loading.show();
        let rs = await window.$Core.Api.Print(
            window.$i18n.global.t("url.InVe"),
            {
                MaGiaoDich: maGiaoDich,
                layFilePdf: layFilePdf,
            }
        ).Get();
        window.$loading.hide();
        return rs;
    }
    async laySoLuongVeBanTheoLoaiDiemBan(guidChuyenDi) {
        window.$loading.show();
        let rs = await window.$Core.Api.DonHang(
            window.$i18n.global.t("url.LaySoLuongVeBanTheoLoaiDiemBan"),
            { GuidChuyenDi: guidChuyenDi }
        ).Get();
        window.$loading.hide();
        if (rs.StatusCode === 200) {
            return rs.Data.data;
        }
        return [];
    }
    async layDanhSachKhachMuaVeKhongCoSoDoCho(guidChuyenDi) {
        window.$loading.show();
        let rs = await window.$Core.Api.DonHang(
            window.$i18n.global.t("url.KhongCoSoDoCho_LayDanhSachKhachMuaVe"),
            { GuidChuyenDi: guidChuyenDi }
        ).Get();
        window.$loading.hide();
        return rs;
    }
    async layDanhSachMuaVeGhePhu(guidChuyenDi) {
        window.$loading.show();
        let rs = await window.$Core.Api.DonHang(
            window.$i18n.global.t("url.DanhSachMuaVeGhePhu"),
            { guidChuyenDi: guidChuyenDi }
        ).Get();
        window.$loading.hide();
        return rs;
    }
    async phatHanhVeTheoMaDatCho(data) {
        window.$loading.show();
        let rs = await window.$Core.Api.DonHang(
            window.$i18n.global.t("url.PhatHanhVeTheoMaDatCho")
        ).Post({ guidChuyenDi: data.guidChuyenDi, maDatCho: data.maDatCho });
        window.$loading.hide();
        return rs;
    }
}
export { DonHang as default };
